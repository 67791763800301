import React from "react";

import './style.css'
const WhatsAppButton = () => {
    return(
       <div id="wbutton">
            <div className="row justify-content-center">
                <div className="col-lg-6 col-md-10 d-flex">
                    {/* <input type="text" className="form-control" placeholder="Enter your Email"/> */}
                    <a href="https://wa.me/523313283216/?text=%C2%A1Hola!%20Me%20gustaría%20tener%20atención%20personalizada%20y%20conocer%20sus%20servicios%20y%20costos.%20" target="{_blank}">
                        <button type="submit" className="ms-2">Click Aquí</button>
                    </a>
                </div>
            </div>
       </div>
      

    )
}

export default WhatsAppButton;