import React from "react";

import SocialMedia from "../../containers/SocialMedia";

import "./style.css";

const BiblicalPhrase = () => {
  return (
    <section id="contact" >
      <div className="container" data-aos="fade-up">
        <div className="row">
          <div className="text-center">
            <h3 className="cont">Contáctanos</h3>
            {/* <p>
              El amor es sufrido, es benigno; el amor no tiene envidia, el amor
              no es jactancioso, no se envanece; no hace nada indebido, no busca
              lo suyo, no se irrita, no guarda rencor; no se goza de la
              injusticia, más se goza de la verdad.
            </p> */}
            <SocialMedia/>
          </div>
          {/* <div className="col-lg-3">
                        <h3>Where</h3>
                        <p>Downtown Conference Center, New York</p>
                    </div> */}
          {/* <div className="col-lg-3">
                        <h3>When</h3>
                        <p>Monday to Wednesday<br></br>10-12 December</p>
                    </div> */}
        </div>
      </div>
    </section>
  );
};

export default BiblicalPhrase;
