import React from "react";
// import { useParams } from "react-router-dom";
import BackToTopButton from "./containers/BackToTop";

import Header from "./components/Header";
import Hero from "./components/Hero";
import Introduction from "./components/Introduction";
import Contact from "./components/Contact";
import Memories from "./components/Memories";
import CallToAction from "./components/CallToAction";
import Gallery from "./components/Gallery";
import Pricing from "./components/Pricing";
import Footer from "./components/Footer";
import WhatsAppButtonLeft from "./containers/WhatsAppButtonLeft";

const Main = () => {
  return (
    <React.Fragment>
      <Header />
      <Hero />
      <Introduction />
      <Memories />
      <Gallery />
      <CallToAction />
      <Pricing />
      <Contact />
      <Footer />
      <WhatsAppButtonLeft />
      <BackToTopButton />
    </React.Fragment>
  );
};

export default Main;
