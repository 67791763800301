import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import images from "../../assets/images";
import "./style.css";

const Header = () => {
  const logo = images.logo.logo;
  const [color, setColor] = useState("transparent");
  const [showMenu, setShowMenu] = useState(false);
  const listenScrollEvent = (e) => {
    if (window.scrollY > 200) {
      setColor("rgba(0, 0, 0, .7)");
    } else {
      setColor("transparent");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  const menuClass = showMenu ? "shown" : "has-burger";

  return (
    <header
      id="header"
      className="d-flex align-items-center "
      style={{ backgroundColor: color }}
    >
      <div className="container-fluid container-xxl d-flex align-items-center">
        <div id="logo" className="me-auto">
          {/* Uncomment below if you prefer to use a text logo */}
          <Link to="/" className="scrollto">
            <img className="img-fluid" src={logo} alt="" title="" />
          </Link>
        </div>

        <nav
          id="navbar"
          className={`navbar order-last order-lg-0 ${menuClass}`}
        >
          <ul>
            <li className="close" onClick={() => setShowMenu(false)}></li>
            <li className="burger" onClick={() => setShowMenu(true)}></li>
            <li>
              <a className="nav-link scrollto active" href="#hero">
                Home
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="#about">
                Nosotros
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="#memories">
                Memories
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="#gallery">
                Gallery
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="#buy">
                Experiencias
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="#contact">
                Contacto
              </a>
            </li>

            {/* <li><a className="nav-link scrollto active" href="#hero">Home</a></li>
                    <li><a className="nav-link scrollto" href="#couple">Nosotros</a></li>
                    <li><a className="nav-link scrollto" href="#padrinos">Padrinos</a></li>
                    <li><a className="nav-link scrollto" href="#ceremonia">Ceremonia</a></li>
                    <li><a className="nav-link scrollto" href="#mesa">Mesa</a></li>
                    <li><a className="nav-link scrollto" href="#seguridad">Seguridad</a></li>
                    <li><a className="nav-link scrollto" href="#asistencia">Asistencia</a></li> */}

            {/*<!-- <li class="dropdown"><a href="#"><span>Drop Down</span> <i class="bi bi-chevron-down"></i></a>
                    <ul>
                    <li><a href="#">Drop Down 1</a></li>
                    <li class="dropdown"><a href="#"><span>Deep Drop Down</span> <i class="bi bi-chevron-right"></i></a>
                        <ul>
                        <li><a href="#">Deep Drop Down 1</a></li>
                        <li><a href="#">Deep Drop Down 2</a></li>
                        <li><a href="#">Deep Drop Down 3</a></li>
                        <li><a href="#">Deep Drop Down 4</a></li>
                        <li><a href="#">Deep Drop Down 5</a></li>
                        </ul>
                    </li>
                    <li><a href="#">Drop Down 2</a></li>
                    <li><a href="#">Drop Down 3</a></li>
                    <li><a href="#">Drop Down 4</a></li>
                    </ul>
                    </li> */}
            {/* <li><a className="nav-link scrollto" href="#contact">Contact</a></li> */}
          </ul>
        </nav>
        {/*.navbar */}
      </div>
    </header>
  );
};

export default Header;
