import React from "react";
import { Link } from "react-router-dom";

import "./style.css";

const Footer = () => {
  return (
    <div>
      <footer id="footer">
        <div className="container">
          <div className="copyright">
            &copy; Copyright <strong> 2022 </strong>. All Rights Reserved
          </div>
          <div className="credits">
            Designed by <a href="https://wa.me/523311962551/?text=%C2%A1Hola!%20quiero%20una%20web! " target="{_blank}"> <span className="vf-footer">VF</span> </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
