import React from "react";

import images from "../../assets/images";
import "./style.css";

const Memories = () => {
  const backgroundImage = `url(${images.countdown.countdown})`;
  const logo = images.logo.logo;

  return (
    <section id="memories" style={{ backgroundImage }}>
      <div className="container text-center" data-aos="zoom-in">
        <div className="section-header">
          {/* <h2>Newsletter</h2> */}
          <h6>We will create your best memories</h6>
          {/* <p>Rerum numquam illum recusandae quia mollitia consequatur.</p> */}

          {/* <img className="img-fluid" src={logo} alt="" /> */}
          <div>
            <img className="img-fluid-logo-CallToAction" src={logo} alt="" title="" />
          </div>
        </div>

        {/* <form method="POST" action="#">
                    <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-10 d-flex">
                    <input type="text" className="form-control" placeholder="Enter your Email" />
                    <button type="submit" className="ms-2">Subscribe</button>
                    </div>
                    </div>
                </form> */}
      </div>
    </section>
  );
};

export default Memories;
