import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Main from "./Main";
import NotFound from "./containers/Err404";

import Home from "./components/Home";

import AOS from "aos";
import "aos/dist/aos.css";

const App = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/sonido-trece" element={<Main />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
