import React from "react";

import "./style.css";

const Introduction = () => {
  
  return (
    // <!-- ======= About Section ======= -->
    <section id="about">
      <div className="container" data-aos="fade-up">
        <div className="row">
          <div className="col-lg-6">
            <h2>About us</h2>
            <p>
              Sonido Trece nace de la pasión y la euforia de crear una propuesta musical de elevada categoría 
              desarrollando al máximo el potencial de cada uno de sus elementos. 
            </p>

            <p>
              Estamos por cumplir 30 años de carrera artística creando momentos únicos. Diferenciando cada uno 
              de nuestros eventos por proporcionar siempre originalidad y una propuesta diferente.
            </p>

            <p>
              En nuestro tiempo de ejecución detonamos excelentes y diversos géneros musicales como Jazz, Salsa,
              Cumbia, Rock, Blues, Disco, Pop, Reggae, Banda Sonora y muchos más. Permite que Sonido Trece armonice
              las horas más especiales y con ello llevaremos al máximo el climax de tu celebración.
            </p>

            <p>
              Nos emociona compartir y crear una emoción diferente en nuestros espectadores, sabemos la 
              importancia de nuestra energía y lo que puede hacer la música en vivo.
            </p>
          </div>
          <div className="col-lg-3">
            <h3>Producción</h3>
            <p>Ingeniería en iluminación</p>
            <p>Ingeniería en audio</p>
            <p>Dj Especializado</p>
          </div>
          <div className="col-lg-3">
            <h3>Dirección</h3>
            <p>Master of ceremonies</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Introduction;
