import React from "react";

import WhatsAppButton from "../../containers/WhatsAppButton";
import foto from './background.jpg'
import './style.css';
const CallToAction = () => {
    const backgroundImage = `url(${foto})`;
    return(
        <section id="subscribe" style={{ backgroundImage }}>
            <div className="container" data-aos="zoom-in">
                <div className="section-header">
                    <h2>Contrataciones</h2>
                    <p className="te-gustaria">¿Te gustaría recibir mayor información de nuestros servicios?</p>
                </div>
                <WhatsAppButton/>
            </div>
        </section>
    )
}

export default CallToAction;