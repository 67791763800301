import React from "react";

import WhatsAppButton from "../../containers/WhatsAppButton";
import './style.css';

const Pricing = () => {
    return(
        <section id="buy" className="section-with-bg">
            <div className="container" data-aos="fade-up">
                <div className="section-header">
                    <h2>Experiencias</h2>
                    <p>Creamos de tu celebración el mejor recuerdo</p>
                </div>
                <div className="row">
                    <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
                    <div className="card mb-5 mb-lg-0">
                        <div className="card-body">
                        <h5 className="card-title text-muted text-uppercase text-center"> Access</h5>
                        {/* <h6 className="card-price text-center">$150</h6> */}
                        <hr />
                        <ul className="fa-ul">
                            <li><span className="fa-li"><i className="fa fa-check"></i></span>Música completamente en vivo.</li>
                            <li><span className="fa-li"><i className="fa fa-check"></i></span>Elementos en escena, metales, batería, percusión, piano, guitarra, bajo, voces.</li>
                            <li><span className="fa-li"><i className="fa fa-check"></i></span>Dj especializado.</li>
                            <li><span className="fa-li"><i className="fa fa-check"></i></span>Escenario o Sobre escenario.</li>
                            <li><span className="fa-li"><i className="fa fa-check"></i></span>Ingeniero de audio e iluminación.</li>
                            <li><span className="fa-li"><i className="fa fa-check"></i></span>Staff Técnico.</li>
                            <li><span className="fa-li"><i className="fa fa-check"></i></span>Maestro de ceremonias.</li>
                            
                            {/* <li className="text-muted"><span className="fa-li"><i className="fa fa-times"></i></span>Workshop Access</li>
                            <li className="text-muted"><span className="fa-li"><i className="fa fa-times"></i></span>After Party</li> */}
                        </ul>
                        <hr />
                        {/* <div className="text-center">
                            <button type="button" className="btn" data-bs-toggle="modal" data-bs-target="#buy-ticket-modal" data-ticket-type="standard-access">Buy Now</button>
                        </div> */}
                        <WhatsAppButton/>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-4" data-aos="fade-up" data-aos-delay="200">
                    <div className="card mb-5 mb-lg-0">
                        <div className="card-body">
                        <h5 className="card-title text-muted text-uppercase text-center">Access</h5>
                        {/* <h6 className="card-price text-center">$250</h6> */}
                        <hr />
                        <ul className="fa-ul">
                            <li><span className="fa-li"><i className="fa fa-check"></i></span>Armonización de elementos en ceremonia religiosa.</li>
                            <li><span className="fa-li"><i className="fa fa-check"></i></span>Coctel de bienvenida.</li>
                            <li><span className="fa-li"><i className="fa fa-check"></i></span>Show de Piano.</li>
                            <li><span className="fa-li"><i className="fa fa-check"></i></span>Sonorización de mariachi.</li>
                            <li><span className="fa-li"><i className="fa fa-check"></i></span>Sonorización de banda.</li>
                            <li><span className="fa-li"><i className="fa fa-check"></i></span>Ballet profesional.</li>
                            <li><span className="fa-li"><i className="fa fa-check"></i></span>DJ audio e iluminación.</li>
                            {/* <li className="text-muted"><span className="fa-li"><i className="fa fa-times"></i></span>Workshop Access</li>
                            <li className="text-muted"><span className="fa-li"><i className="fa fa-times"></i></span>After Party</li> */}
                        </ul>
                        <hr />
                        {/* <div className="text-center">
                            <button type="button" className="btn" data-bs-toggle="modal" data-bs-target="#buy-ticket-modal" data-ticket-type="pro-access">Buy Now</button>
                        </div> */}
                        <WhatsAppButton/>
                        </div>
                    </div>
                    </div>
                    {/* <!-- Pro Tier --> */}
                    <div className="col-lg-4" data-aos="fade-up" data-aos-delay="300">
                    <div className="card">
                        <div className="card-body">
                        <h5 className="card-title text-muted text-uppercase text-center">Access</h5>
                        {/* <h6 className="card-price text-center">$350</h6> */}
                        <hr />
                        <ul className="fa-ul">
                            <li><span className="fa-li"><i className="fa fa-check"></i></span>Pantallas gigantes.</li>
                            <li><span className="fa-li"><i className="fa fa-check"></i></span>Pantallas Leds.</li>
                            <li><span className="fa-li"><i className="fa fa-check"></i></span>Banner Led.</li>
                            <li><span className="fa-li"><i className="fa fa-check"></i></span>Souvenirs de alta calidad.</li>
                            <li><span className="fa-li"><i className="fa fa-check"></i></span>Pirotecnia fría.</li>
                            <li><span className="fa-li"><i className="fa fa-check"></i></span>Eventos Destino.</li>
                            <li><span className="fa-li"><i className="fa fa-check"></i></span>Pistas cristal, madera leds, charol, madera cristal, madera & pintada a mano.</li>
                        </ul>
                        <hr />
                        {/* <div className="text-center">
                            < button type="button" className="btn" data-bs-toggle="modal" data-bs-target="#buy-ticket-modal" data-ticket-type="premium-access">Buy Now</>
                        </div> */}
                        <WhatsAppButton/>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Pricing;