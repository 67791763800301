import React from "react";

import SocialMedia from "../../containers/SocialMedia";
import { Link } from "react-router-dom";
import images from "../../assets/images";
import "./style.css"

const Home = () => {
    const logo = images.logo.logo;
    return(
        <section id="Home">
            <video autoPlay loop muted playsInline>
                <source
                 src="https://res.cloudinary.com/sonido-13/video/upload/v1661733896/HomeVideo/homesonido_rb1etn.mp4"
                 type="video/mp4"
                />
            </video>
            <div className="text-video">
                {/* <h1>Sonido Trece</h1> */}
                {/* <h3>08 | 10 | 2022</h3> */}
                {/* <h2 >Valeria & Flavio</h2> */}
                <img className="img-fluid-logo-home" src={logo} alt="" title="" />
                
                {/* <h6>We will become a family in</h6> */}
                
                <div className="form">
                    <div className="php-email-form-login text-center">
                        <Link to="/sonido-trece">
                            <button type="submit">Entrar</button>
                        </Link>
                    </div>
                </div>
                <SocialMedia/>
            </div>
        </section>
    )
}

export default Home;




