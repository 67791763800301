import React, { useState, useCallback } from "react";
// import ReactDOM from 'react-dom';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

function ExampleWithLightbox({ photos }) {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
    // document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    // document.getElementsByTagName('body')[0].style.position = 'static';
    // document.getElementsByTagName('body')[0].style.height = '100vh';
    // document.getElementsByTagName('body')[0].style.width = '100vw';
    document.body.style.overflowY = "hidden";
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
    document.body.style.overflow = "auto";
    // document.getElementsByTagName('body')[0].style.overflowY = 'visible';
    // document.getElementsByTagName('body')[0].style.position = 'initial';
  };

  return (
    <div>
      {/* <h2>Using with a Lightbox component</h2> */}
      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}

export default ExampleWithLightbox;
