import logo from "./logo";
import hero from "./hero";
import countdown from "./countdown";

const obj = {
  logo,
  hero,
  countdown,
};

export default obj;
