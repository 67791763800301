import React, { Component } from "react";

import "./style.css";

class WhatsAppButton extends Component {

  render() {
    return (
      <div>
        {/* Button to call our main function */}
        <button
          className="button-icon-song shrink-on-hover-button">
          <a href="https://wa.me/523313283216/?text=%C2%A1Hola!%20Me%20gustaría%20tener%20atención%20personalizada%20y%20conocer%20sus%20servicios%20y%20costos.%20" target="{_blank}" className="icon_wrappper whatsapp">                   
          <img
            className="song-icon shrink-on-hover-button"
            src="https://img.icons8.com/ios/50/000000/whatsapp--v1.png"
            alt="music"/>
          </a>
        </button>
      </div>
    );
  }
}

export default WhatsAppButton;
